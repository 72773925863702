import React from "react";
import BlogList from "../BlogList/BlogList";

const UserDashboard = () => {
  // Your code for user authentication and login

  return (
    <div>
      <BlogList />
    </div>
  );
};

export default UserDashboard;
